import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Descriptions,
  Image,
  message,
  Row,
  Spin,
  Tabs,
  Typography,
} from "antd";
import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { useParams } from "react-router-dom";
import {
  getTransactionById,
  updateTransaction,
} from "../api/TransactionManagementAPI";
import { ITransaction } from "../type/TransactionManagement";
import TransactionEdit from "./TransactionEdit";

const { Title } = Typography;

const TransactionDetails = () => {
  const { id: transactionId } = useParams<{ id: string }>();
  const [editVisible, setEditVisible] = useState<boolean>(false);

  const {
    data: transaction,
    isLoading,
    isError,
    refetch: refetchGetTransactionById,
  } = useQuery(
    ["companyData", transactionId],
    () => getTransactionById(transactionId),
    {
      enabled: !!transactionId,
    }
  );
  const updateTransactionMutation = useMutation(updateTransaction, {
    onSuccess: (data) => {
      refetchGetTransactionById();
      message.success("Company package updated successfully!");
      setEditVisible(false);
    },
    onError: (error: Error) => {
      message.error(`Failed to edit company package: ${error.message}`);
    },
  });

  const handleUpdate = async (values: ITransaction) => {
    const { data } = await updateTransactionMutation.mutateAsync(values);
  };

  const handleEdit = () => {
    setEditVisible(true);
  };

  const handleCancel = () => {
    setEditVisible(false);
  };

  if (isLoading) {
    return <Spin tip="Loading..." />;
  }

  if (isError || !transaction) {
    return <div>Failed to load transaction details</div>;
  }

  const DESCRIPTION_ITEMS = [
    { label: "Company Name", children: transaction.companyId.companyName },
    { label: "Package Name", children: transaction.packageId.name },
    {
      label: "Package Description",
      children: transaction.packageId.description,
    },
    { label: "Amount", children: transaction.amount },
    { label: "Currency", children: transaction.currency },
    { label: "Status", children: transaction.status },
    {
      label: "Created at",
      children: new Date(transaction.createdAt).toLocaleString(),
    },
  ];

  return (
    <Card
      style={{
        borderRadius: 8,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "16px",
        margin: "16px",
      }}
    >
      <TransactionEdit
        visible={editVisible}
        onCancel={handleCancel}
        onCreate={handleUpdate}
        initialData={transaction}
      />
      <Title level={2} style={{ marginBottom: "30px", textAlign: "center" }}>
        Transaction Details
      </Title>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={16} lg={24}>
          <Descriptions
            bordered
            column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }}
            style={{ marginBottom: "16px" }}
          >
            {DESCRIPTION_ITEMS.map((item, index) => (
              <Descriptions.Item label={item.label} key={index}>
                {item.children}
              </Descriptions.Item>
            ))}
          </Descriptions>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#52c41a",
              }}
              onClick={handleEdit}
            >
              <MdEdit /> &nbsp; Edit Transaction
            </Button>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default TransactionDetails;
