import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { apiRequest } from "src/utils/API";

export interface LocationOptions {
  [state: string]: string[];
}
interface DataReturn {
  locationOptions: LocationOptions | undefined;
  locationOptionsLoading: boolean;
}

export const getLocationOption = async () => {
  const response = await apiRequest<LocationOptions>(
    "GET",
    `/v1/options/locations`
  );
  return response.data;
};

function useLocationOptions(): DataReturn {
  const [locationOptions, setLocationOptions] = useState<LocationOptions>();

  const queryOption = useQuery<LocationOptions>({
    queryKey: ["location-option"],
    queryFn: () => getLocationOption(),
    onSuccess: (data) => {
      if (data) {
        setLocationOptions(data);
      }
    },
  });

  return {
    locationOptions,
    locationOptionsLoading: queryOption.isLoading,
  };
}

export default useLocationOptions;
