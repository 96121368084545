/* eslint-disable no-useless-catch */
import { apiRequest, downloadFile } from "src/utils/API";
import queryToString from "src/utils/queryToString";
import {
  IAntDProTableRequest,
  IAPIResponseGetJobs,
  IJob,
  IScreeningQuestion,
} from "../type/JobManagement";
import formatDateTime from "src/utils/formatDateTime";

export const getJobs = async ({
  sort,
  filter,
  params,
}: IAntDProTableRequest) => {
  params.limit = params.pageSize;
  params.page = params.current;
  delete params.pageSize;
  delete params.current;
  const sortKey = Object.keys(sort)[0];
  const sortValue = sort[sortKey] === "ascend" ? "1" : "-1";
  const newParams = queryToString({
    ...params,
    ...filter,
    sortBy: sortKey,
    sortOrder: sortValue,
  });
  const response = await apiRequest<IAPIResponseGetJobs[]>(
    "GET",
    `/v1/admin/job/list?${newParams}`
  );

  return response.data;
};
export const getJob = async (id: string | undefined) => {
  const response = await apiRequest<IAPIResponseGetJobs>(
    "GET",
    `/v1/admin/job/${id}`
  );
  return response.data;
};

export const createJob = async (data: IJob) => {
  return await apiRequest<IJob>("POST", "/v1/admin/job", data);
};

export const downloadJobList = async () => {
  const fileName = `Job_List_${formatDateTime(new Date())}.csv`;
  await downloadFile("/v1/admin/job/list/download", fileName);
};

export const updateJob = async (data: IJob) => {
  const payload = {
    ...data,
    screeningQuestions: data.screeningQuestions?.map((item) => item.id),
  };

  return await apiRequest<IJob>("PATCH", "/v1/admin/job", payload);
};

export const updateJobScreeningQuestion = async (data: {
  id: string;
  screeningQuestions: string[];
}) => {
  return await apiRequest<IJob>("PATCH", "/v1/admin/job", data);
};

export const createScreeningQuestion = async (data: IScreeningQuestion) => {
  return await apiRequest<IScreeningQuestion[]>(
    "POST",
    "/v1/admin/screening-question",
    data
  );
};

export const updateScreeningQuestion = async (data: IScreeningQuestion) => {
  return await apiRequest<IJob>("PATCH", "/v1/admin/screening-question", data);
};

export const postJob = async (data: {
  jobId: string;
  jobPostPackageId: string;
}) => {
  return await apiRequest<any>("POST", "/v1/admin/job/post", data);
};
