import { ProColumns, ProTable } from "@ant-design/pro-components";
import { useMutation } from "@tanstack/react-query";
import { Button, message, Typography, Select } from "antd";
import { useState } from "react";
import { MdAdd, MdDownload } from "react-icons/md";
import { CheckCircleFilled } from "@ant-design/icons";

import {
  createCompany,
  downloadCandidateList,
  getCompanies,
  uploadCompanyLogo,
} from "./api/CompanyManagementAPI";
import { ICompany } from "./type/CompanyManagement";
import CompanyAdd from "./view/CompanyAdd";
import useIndustryCategoryOptions from "src/hooks/useIndustryCategory";
import useLocationOptions from "src/hooks/useLocationOption";

const CompanyManagement = () => {
  const { Title } = Typography;

  const { industryCategoryOptions } = useIndustryCategoryOptions();
  const { locationOptions } = useLocationOptions();

  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const createCompanyMutation = useMutation(createCompany, {
    onSuccess: (data) => {
      message.success("Company created successfully!");
      window.location.reload();
      setVisible(false);
    },
    onError: (error: Error) => {
      message.error(`Failed to add company: ${error.message}`);
    },
  });

  const uploadLogoMutation = useMutation(uploadCompanyLogo, {
    onError: (error: Error) => {
      message.error(`Failed to upload logo: ${error.message}`);
    },
  });

  const handleCreate = async (values: ICompany, logo: File) => {
    const { data } = await createCompanyMutation.mutateAsync(values);
    if (data && logo) {
      await uploadLogoMutation.mutateAsync({ companyId: data.id, logo });
    }
  };

  const waitTimePromise = async (time: number = 100) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  };

  const waitTime = async (time: number = 100) => {
    await waitTimePromise(time);
  };

  const industryCategoryOptionsEnum = industryCategoryOptions?.reduce(
    (acc, specialization) => {
      // @ts-expect-error ignore
      acc[specialization] = { text: specialization };
      return acc;
    },
    {}
  );

  const formattedDate = (date: Date) => {
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  };

  const columns: ProColumns<ICompany>[] = [
    {
      title: "Name",
      dataIndex: "companyName",
      key: "companyName",
      width: 200,
      render: (text, record) => (
        <a href={`/users/company/${record.id}`}>
          {record.companyName} {record.verified && <CheckCircleFilled />}
        </a>
      ),
    },
    {
      title: "Billing Email",
      dataIndex: "billingEmail",
      key: "billingEmail",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Location State",
      dataIndex: "locationState",
      key: "locationState",
      valueType: "select",
      renderFormItem: (_, { defaultRender }, form) => {
        return (
          <Select
            placeholder="Select State"
            onChange={(value) => {
              form.setFieldValue("locationState", value);
              form.setFieldValue("locationCity", undefined);
            }}
          >
            {locationOptions &&
              Object.keys(locationOptions).map((state) => (
                <Select.Option key={state} value={state}>
                  {state}
                </Select.Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "Location City",
      dataIndex: "locationCity",
      key: "locationCity",
      renderFormItem: (_, { defaultRender, ...rest }, form) => {
        const selectedState = form.getFieldValue("locationState");

        // @ts-expect-error selectedState is string
        const cities = selectedState ? locationTypes[selectedState] : [];

        return (
          <Select
            placeholder="Select City"
            disabled={!selectedState}
            key={selectedState || "empty"}
          >
            {cities.map((city: string) => (
              <Select.Option key={city} value={city}>
                {city}
              </Select.Option>
            ))}
          </Select>
        );
      },
      dependencies: ["locationState"],
    },
    {
      title: "Address",
      dataIndex: "officeAddress",
      key: "officeAddress",
    },
    {
      title: "Industry Category",
      dataIndex: "industryCategory",
      valueEnum: industryCategoryOptionsEnum,
    },
    {
      title: "Total Employees",
      dataIndex: "totalEmployees",
      key: "totalEmployees",
    },
    {
      title: "Verified",
      dataIndex: "verified",
      key: "verified",
      hideInTable: true,
      valueEnum: {
        Yes: { text: "Yes" },
        No: { text: "No" },
      },
      render: (text, record) => <>{record?.verified ? "Yes" : "No"}</>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div>{formattedDate(new Date(record.createdAt))}</div>
      ),
      valueType: "dateRange",
    },
  ];

  return (
    <div style={{ paddingInline: 20 }}>
      <CompanyAdd
        visible={visible}
        onCreate={handleCreate}
        onCancel={handleCancel}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title level={3} style={{ marginBottom: "1em" }}>
          Company Management
        </Title>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1em",
          }}
        >
          <Button
            type="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={showModal}
          >
            <MdAdd /> &nbsp; Add Company
          </Button>
          <Button
            type="primary"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            onClick={downloadCandidateList}
          >
            <MdDownload /> &nbsp; Download CSV
          </Button>
        </div>
      </div>
      <ProTable
        cardBordered
        form={{ layout: "vertical" }}
        request={async (params, sort, filter) => {
          try {
            const data = await getCompanies({ filter, sort, params });
            const user = data.results;

            return {
              data: user,
              success: true,
              total: data.totalResults,
            };
          } catch (error) {
            return {
              data: [],
              success: false,
              total: 10,
            };
          }
        }}
        rowKey={(data) => data.id}
        columns={columns}
        editable={{
          type: "multiple",
          onSave: async (rowKey, data, row) => {
            await waitTime(2000);
          },
        }}
        scroll={{
          x: "max-content",
        }}
      />
    </div>
  );
};

export default CompanyManagement;
