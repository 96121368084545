import React from "react";
import { Col, Form, Modal, Row, Select } from "antd";

import useJobPostPackageOptions from "src/hooks/useJobPostPackageOptions";

const { Option } = Select;

interface EditJobFormProps {
  visible: boolean;
  onSubmit: (values: string) => void;
  onCancel: () => void;
}

const PostJob: React.FC<EditJobFormProps> = ({
  visible,
  onSubmit,
  onCancel,
}) => {
  const { jobPostPackageOptions } = useJobPostPackageOptions();

  const [form] = Form.useForm();

  const handleStateChange = (value: string) => {
    form.setFieldsValue({ jobPostPackageId: value });
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((value) => {
        onSubmit(value.jobPostPackageId);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={visible}
      width={600}
      title="Post Job"
      okText="Post Job"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12} md={24}>
            <Form.Item
              name="jobPostPackageId"
              label="Job Post Package"
              rules={[
                {
                  required: true,
                  message: "Please select job post package",
                },
              ]}
            >
              <Select
                placeholder="Select Job Post Package"
                onChange={(value) => handleStateChange(value)}
              >
                {jobPostPackageOptions?.results?.map((option: any) => (
                  <Option key={option.id} value={option.id}>
                    {option.name} - {option.periodDays} Active Days - MYR{" "}
                    {option.price}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PostJob;
