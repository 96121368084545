import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { apiRequest } from "src/utils/API";

export type SalaryOptions = string[];

interface DataReturn {
  salaryOptions: SalaryOptions | undefined;
  salaryOptionsLoading: boolean;
}

export const getSalaryOption = async () => {
  const response = await apiRequest<SalaryOptions>("GET", `/v1/options/salaries`);
  return response.data;
};

function useSalaryOptions(): DataReturn {
  const [salaryOptions, setSalaryOptions] = useState<SalaryOptions>();

  const queryOption = useQuery<SalaryOptions>({
    queryKey: ["salary-option"],
    queryFn: () => getSalaryOption(),
    onSuccess: (data) => {
      if (data) {
        setSalaryOptions(data);
      }
    },
  });

  return {
    salaryOptions,
    salaryOptionsLoading: queryOption.isLoading,
  };
}

export default useSalaryOptions;
