import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  Descriptions,
  message,
  Row,
  Spin,
  Typography,
} from "antd";
import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { useParams } from "react-router-dom";
import {
  getTalentSearchPackageById,
  updateTalentSearchPackage,
} from "../api/TalentSearchPackageManagementAPI";
import { ITalentSearchPackage } from "../type/TalentSearchPackageManagement";
import TalentSearchPackageEdit from "./TalentSearchPackageEdit";

const { Title } = Typography;

const TalentSearchPackageDetails = () => {
  const { id: talentSearchPackageId } = useParams<{ id: string }>();
  const [editVisible, setEditVisible] = useState<boolean>(false);

  const {
    data: talentSearchPackage,
    isLoading,
    isError,
    refetch: refetchGetTalentSearchPackageById,
  } = useQuery(
    ["talentSearchData", talentSearchPackageId],
    () => getTalentSearchPackageById(talentSearchPackageId),
    {
      enabled: !!talentSearchPackageId,
    }
  );
  const updateTalentSearchPackageMutation = useMutation(
    updateTalentSearchPackage,
    {
      onSuccess: (data) => {
        refetchGetTalentSearchPackageById();
        message.success("Talent Search Package updated successfully!");
        setEditVisible(false);
      },
      onError: (error: Error) => {
        message.error(`Failed to edit Talent Search package: ${error.message}`);
      },
    }
  );

  const handleUpdate = async (values: ITalentSearchPackage) => {
    const { data } =
      await updateTalentSearchPackageMutation.mutateAsync(values);
  };

  const handleEdit = () => {
    setEditVisible(true);
  };

  const handleCancel = () => {
    setEditVisible(false);
  };

  if (isLoading) {
    return <Spin tip="Loading..." />;
  }

  if (isError || !talentSearchPackage) {
    return <div>Failed to load Talent Search Package details</div>;
  }

  const DESCRIPTION_ITEMS = [
    {
      label: "Status",
      children: talentSearchPackage.isActive ? "Active" : "Inactive",
    },
    { label: "Package Name", children: talentSearchPackage.name },
    { label: "Description", children: talentSearchPackage.description },
    { label: "Price (MYR)", children: talentSearchPackage.price },
    {
      label: "Talent Search Credit",
      children: talentSearchPackage.talentSearchCredit,
    },
    {
      label: "Validity Period (days)",
      children: talentSearchPackage.validityPeriodDays,
    },
  ];

  return (
    <Card
      style={{
        borderRadius: 8,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "16px",
        margin: "16px",
      }}
    >
      <TalentSearchPackageEdit
        visible={editVisible}
        onCancel={handleCancel}
        onCreate={handleUpdate}
      />
      <Title level={2} style={{ marginBottom: "30px", textAlign: "center" }}>
        Talent Search Package Details
      </Title>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={16} lg={24}>
          <Descriptions
            bordered
            column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }}
            style={{ marginBottom: "16px" }}
          >
            {DESCRIPTION_ITEMS.map((item, index) => (
              <Descriptions.Item label={item.label} key={index}>
                {item.children}
              </Descriptions.Item>
            ))}
          </Descriptions>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={handleEdit}
            >
              <MdEdit /> &nbsp; Edit Talent Search Package
            </Button>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default TalentSearchPackageDetails;
