import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { apiRequest } from "src/utils/API";

export interface JobPostPackage {
  [state: string]: any;
}
interface DataReturn {
  jobPostPackageOptions: JobPostPackage | undefined;
  jobPostPackageOptionsLoading: boolean;
}

export const getJobPostPackageOption = async () => {
  const response = await apiRequest<JobPostPackage>(
    "GET",
    `/v1/admin/package/list?limit=20&page=1`
  );
  return response.data;
};

function useJobPostPackage(): DataReturn {
  const [jobPostPackageOptions, setJobPostPackage] = useState<JobPostPackage>();

  const queryOption = useQuery<JobPostPackage>({
    queryKey: ["job-post-option"],
    queryFn: () => getJobPostPackageOption(),
    onSuccess: (data) => {
      if (data) {
        setJobPostPackage(data);
      }
    },
  });

  return {
    jobPostPackageOptions,
    jobPostPackageOptionsLoading: queryOption.isLoading,
  };
}

export default useJobPostPackage;
