import { Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { ICompanyPackage } from "../type/CompanyPackageManagement";
const { Option } = Select;

interface EditCompanyPackageFormProps {
  visible: boolean;
  onCreate: (values: any) => void;
  onCancel: () => void;
  initialData?: ICompanyPackage;
}

const CompanyPackageEdit: React.FC<EditCompanyPackageFormProps> = ({
  visible,
  onCreate,
  onCancel,
  initialData,
}) => {
  const { id } = useParams<{ id: string }>();
  const [selectedState, setSelectedState] = useState<string>("");
  const [form] = Form.useForm();

  const handleStateChange = (value: string) => {
    setSelectedState(value);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onCreate({
          isActive: selectedState,
          id,
        });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData);
    }
  }, [initialData, form]);

  return (
    <Modal
      open={visible}
      width={400}
      title="Edit Company Package"
      okText="Update"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item
              name="Status"
              label="Status"
              rules={[{ required: true, message: "Please select is popular" }]}
            >
              <Select
                placeholder="Select One"
                allowClear
                onChange={handleStateChange}
              >
                <Option value={false}>Inactive</Option>
                <Option value={true}>Active</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CompanyPackageEdit;
