import { useState } from "react";
import { ProColumns, ProTable } from "@ant-design/pro-components";
import { useMutation } from "@tanstack/react-query";
import { Button, message, Typography } from "antd";
import { MdAdd, MdDownload } from "react-icons/md";

import JobApplicationAdd from "./view/JobApplicationAdd";
import useJobStatusOptions from "src/hooks/useJobStatusOption";
import useJobApplicationStatusOptions from "src/hooks/useJobApplicationsStatusOptions";

import {
  getJobApplications,
  createJobApplication,
  downloadJobApplicationList,
} from "./api/JobApplicationManagementAPI";
import { IJobApplication } from "./type/JobApplicationManagement";

const JobApplicationManagement = () => {
  const { Title } = Typography;
  const [visible, setVisible] = useState(false);

  const { jobStatusOptions } = useJobStatusOptions();
  const { jobApplicationStatusOptions } = useJobApplicationStatusOptions();

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const createJobApplicationMutation = useMutation(createJobApplication, {
    onSuccess: () => {
      message.success("Job application created successfully!");
      setVisible(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    onError: (error: Error) => {
      message.error(`Failed to create job application: ${error.message}`);
    },
  });

  const handleCreate = ({
    values,
    answers,
    resume,
  }: {
    values: IJobApplication;
    answers: any;
    resume?: File;
  }) => {
    createJobApplicationMutation.mutate({ data: values, answers, resume });
  };

  const jobStatusValueEnum = jobStatusOptions?.reduce(
    (acc, status) => {
      acc[status.toLowerCase()] = { text: status, status: "Default" };
      return acc;
    },
    {} as Record<string, { text: string; status: string }>
  );

  const jobApplicationStatusValueEnum = jobApplicationStatusOptions?.reduce(
    (acc, status) => {
      acc[status.toLowerCase()] = { text: status, status: "Default" };
      return acc;
    },
    {} as Record<string, { text: string; status: string }>
  );

  const formattedDate = (date: Date) => {
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(date);
  };

  const columns: ProColumns<IJobApplication>[] = [
    {
      title: "Candidate Name",
      dataIndex: ["candidate", "candidateName"],
      key: "candidateName",
      render: (text, record) => (
        <a href={`/job-application/${record._id}`}>
          {record.candidate.candidateName}
        </a>
      ),
    },
    {
      title: "Job Title",
      dataIndex: ["job", "jobTitle"],
      key: "jobTitle",
    },
    {
      title: "Company Name",
      dataIndex: ["company", "companyName"],
      key: "companyName",
    },
    {
      title: "Job Status",
      dataIndex: ["job", "status"],
      key: "status",
      valueType: "select",
      valueEnum: {
        open: { text: "Open", status: "Success" },
        paused: { text: "Paused", status: "Warning" },
        expired: { text: "Expired", status: "Error" },
        drafted: { text: "Drafted", status: "Default" },
      },
    },
    {
      title: "Application Status",
      dataIndex: "applicationStatus",
      key: "applicationStatus",
      valueEnum: jobApplicationStatusValueEnum,
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div>{formattedDate(new Date(record.createdAt))}</div>
      ),
      valueType: "dateRange",
    },
  ];

  return (
    <div style={{ paddingInline: 20 }}>
      <JobApplicationAdd
        visible={visible}
        onSubmit={handleCreate}
        onCancel={handleCancel}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1em",
        }}
      >
        <Title level={3}>Job Applications Management</Title>
        <div style={{ display: "flex", gap: "1em" }}>
          <Button type="primary" onClick={showModal} icon={<MdAdd />}>
            Create Job Application
          </Button>
          <Button
            type="primary"
            onClick={downloadJobApplicationList}
            icon={<MdDownload />}
          >
            Download CSV
          </Button>
        </div>
      </div>
      <ProTable<IJobApplication>
        cardBordered
        form={{ layout: "vertical" }}
        request={async (params, sort, filter) => {
          try {
            const data = await getJobApplications({ filter, sort, params });
            return {
              data: data.results,
              success: true,
              total: data.totalResults,
            };
          } catch (error) {
            console.error("Error fetching job applications:", error);
            return {
              data: [],
              success: false,
              total: 0,
            };
          }
        }}
        rowKey="_id"
        columns={columns}
        editable={{
          type: "multiple",
          onSave: async () => {
            await new Promise((resolve) => setTimeout(resolve, 2000));
          },
        }}
      />
    </div>
  );
};

export default JobApplicationManagement;
