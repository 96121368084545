const formatDateTime = (date: Date) => {
  const formattedDate = date.toISOString().split("T")[0].replace(/-/g, "");
  const formattedTime = date
    .toTimeString()
    .split(" ")[0]
    .replace(/:/g, "")
    .slice(0, 4);

  const dateTime = `${formattedDate}_${formattedTime}`;
  return dateTime;
};

export default formatDateTime;
