import { ConfigProvider } from "antd";
import ReactDOM from "react-dom/client";

import App from "./App";
import enUS from 'antd/lib/locale/en_US';
import reportWebVitals from "./reportWebVitals";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ConfigProvider
    locale={enUS}
    theme={{
      token: {
        colorPrimary: "#0c377c",
      },
      components: {
        Button: {
          colorPrimary: "#1DA1F2",
          borderRadius: 27,
          colorPrimaryHover: "#1DA1F2",
          primaryShadow: "0 2px 0 rgba(23, 24, 59, 0.02)",
        },
        Layout: {
          colorBgHeader: "white",
          colorText: "#6C6C6C",
        },
        Menu: {
          colorText: "#c8c8c8",
          colorLinkActive: "#0C377C",
          itemBg: "#17183B",
          itemSelectedColor: "white",
          itemBorderRadius: 0,
          colorPrimaryBorder: "2px solid green",
          itemHoverBg: "none",
          itemHoverColor: "white",
        },
        Breadcrumb: {
          lastItemColor: "#17183B",
          linkColor: "#17183B",
          separatorColor: "#17183B",
        },
        Input: {
          colorBorder: "#A6A6A6",
          borderRadius: 4,
        },
        Select: {
          colorBorder: "#A6A6A6",
          borderRadius: 4,
        },
      },
    }}
  >
    <App />
  </ConfigProvider>
);

reportWebVitals();
