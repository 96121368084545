import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { apiRequest } from "src/utils/API";

export type SkillOptions = string[];

interface DataReturn {
  skillOptions: SkillOptions | undefined;
  skillOptionsLoading: boolean;
}

export const getSkillOption = async () => {
  const response = await apiRequest<SkillOptions>("GET", `/v1/options/skills`);
  return response.data;
};

function useSkillOptions(): DataReturn {
  const [skillOptions, setSkillOptions] = useState<SkillOptions>();

  const queryOption = useQuery<SkillOptions>({
    queryKey: ["skill-option"],
    queryFn: () => getSkillOption(),
    onSuccess: (data) => {
      if (data) {
        setSkillOptions(data);
      }
    },
  });

  return {
    skillOptions,
    skillOptionsLoading: queryOption.isLoading,
  };
}

export default useSkillOptions;
