/* eslint-disable no-useless-catch */
import axios from "axios";
import { apiRequest, downloadFile } from "src/utils/API";
import { getToken } from "src/utils/AuthService";
import queryToString from "src/utils/queryToString";
import formatDateTime from "src/utils/formatDateTime";
import {
  IAntDProTableRequest,
  IAPIResponsegetCompany,
  ICompany,
} from "../type/CompanyManagement";

const apiHost = process.env.REACT_APP_API_HOST;

export const getCompanies = async ({
  sort,
  filter,
  params,
}: IAntDProTableRequest) => {
  params.limit = params.pageSize;
  params.page = params.current;
  delete params.pageSize;
  delete params.current;
  const newParams = queryToString({ ...params, ...filter, ...sort });
  const response = await apiRequest<IAPIResponsegetCompany>(
    "GET",
    `/v1/admin/company/list?${newParams}`
  );

  return response.data;
};
export const getCompany = async (id: string | undefined) => {
  const response = await apiRequest<IAPIResponsegetCompany>(
    "GET",
    `/v1/admin/company/${id}`
  );
  return response.data;
};

export const createCompany = async (data: ICompany) => {
  return await apiRequest<ICompany>("POST", "/v1/admin/company/create", {
    email: data.email,
    password: data.password,
    phone: data.phone,
    companyName: data.companyName,
    industryCategory: data.industryCategory,
    totalEmployees: data.totalEmployees,
    locationState: data.locationState,
    locationCity: data.locationCity,
    websiteUrl: data.websiteUrl,
    linkedinUrl: data.linkedinUrl,
    facebookUrl: data.facebookUrl,
    twitterUrl: data.twitterUrl,
    instagramUrl: data.instagramUrl,
    benefits: data.benefits.map((benefit) => ({
      benefitId: benefit.id,
      description: benefit.description,
    })),
    aboutCompany: data.aboutCompany,
    tagLine: data.tagLine,
    founded: data.founded,
    lifeAt: data.lifeAt,
  });
};

export const downloadCandidateList = async () => {
  const fileName = `Company_List_${formatDateTime(new Date())}.csv`;
  await downloadFile("/v1/admin/company/list/download", fileName);
};

export const uploadCompanyLogo = async (body: {
  companyId: string;
  logo: string | Blob;
}) => {
  const formData = new FormData();
  formData.append("companyId", body.companyId);
  formData.append("logo", body.logo);
  try {
    const token = getToken();
    const response = await axios.post(
      `${apiHost}/v1/companies/upload/logo`,
      formData,
      {
        headers: {
          Authorization: token ? `Bearer ${token.access.token}` : "",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCompany = async (data: ICompany) => {
  return await apiRequest<ICompany>("PATCH", "/v1/admin/company", {
    id: data.id,
    userId: data.userId,
    email: data.email,
    password: data.password,
    phone: data.phone,
    companyName: data.companyName,
    industryCategory: data.industryCategory,
    totalEmployees: data.totalEmployees,
    locationState: data.locationState,
    locationCity: data.locationCity,
    websiteUrl: data.websiteUrl,
    linkedinUrl: data.linkedinUrl,
    facebookUrl: data.facebookUrl,
    twitterUrl: data.twitterUrl,
    instagramUrl: data.instagramUrl,
    benefits: data.benefits.map((benefit) => ({
      benefitId: benefit.id,
      description: benefit.description,
    })),
    aboutCompany: data.aboutCompany,
    tagLine: data.tagLine,
    founded: data.founded,
    lifeAt: data.lifeAt,
    officeAddress: data.officeAddress,
    billingAddress: data.billingAddress,
  });
};

export const uploadCompanyGalleries = async (body: {
  companyId: string;
  galleries: File[];
}) => {
  const formData = new FormData();
  formData.append("companyId", body.companyId);
  body.galleries.forEach((gallery, index) => {
    formData.append("galleries", gallery, gallery.name);
  });

  try {
    const token = getToken();
    const response = await axios.post(
      `${apiHost}/v1/companies/upload/galleries`,
      formData,
      {
        headers: {
          Authorization: token ? `Bearer ${token.access.token}` : "",
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteGallery = async (data: {
  companyId: string;
  galleryLink: string;
}) => {
  return await apiRequest<ICompany>("DELETE", "/v1/companies/gallery", data);
};

export const verifyCompany = async (data: { companyId: string }) => {
    const token = getToken();
    const response = await axios.post(
      `${apiHost}/v1/admin/company/${data.companyId}/verify`,
      {},
      {
        headers: {
          Authorization: token ? `Bearer ${token.access.token}` : "",
        }
      }
    ).catch((error) => {
      throw error;
    });
    return response.data;
}

export const unverifyCompany = async (data: { companyId: string }) => {
    const token = getToken();
    const response = await axios.post(
      `${apiHost}/v1/admin/company/${data.companyId}/unverify`,
      {},
      {
        headers: {
          Authorization: token ? `Bearer ${token.access.token}` : "",
        }
      }
    ).catch((error) => {
      throw error;
    });
    return response.data;
}
