import { apiRequest } from "src/utils/API";
import { ILogin } from "../type/loginType";

export const login = async (data: ILogin) => {
  return await apiRequest<ILogin>("POST", "/v1/auth/login", {
    username: data.phone,
    password: data.password,
    role: "admin",
  });
};
