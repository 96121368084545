import {
    Button,
    Divider,
    Modal,
} from "antd"
import React from "react";

interface IConfirmationModalProps {
    visible: boolean;
    title: string;
    body?: string | React.ReactNode;
    cancelActionText?: string;
    confirmActionText?: string;
    onConfirm: () => void;
    onCancel?: () => void;
    loading?: boolean;
}

const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
    visible,
    title,
    body,
    cancelActionText = "Cancel",
    confirmActionText = "Confirm",
    onConfirm,
    onCancel,
    loading,
}) => {
    return (
        <Modal
            open={visible}
            width={600}
            title={title}
            okText={confirmActionText}
            cancelText={cancelActionText}
            onCancel={onCancel}
            onOk={onConfirm}
            footer={[
                <Button key="back" onClick={onCancel}>
                    {cancelActionText}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    danger
                    onClick={onConfirm}
                    loading={loading}
                >
                    {confirmActionText}
                </Button>,
            ]}
        >
            <Divider />
            <p
                style={{
                    display: "flex",
                    marginTop: 24,
                    marginBottom: 24,
                }}>
                {body}
            </p>
            <Divider />
        </Modal>
    )
}

export default ConfirmationModal;
