import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { apiRequest } from "src/utils/API";

export type IndustryCategoryOptions = string[];

interface DataReturn {
  industryCategoryOptions: IndustryCategoryOptions | undefined;
  industryCategoryOptionsLoading: boolean;
}

export const getIndustryCategoryOption = async () => {
  const response = await apiRequest<IndustryCategoryOptions>("GET", `/v1/options/industries`);
  return response.data;
};

function useIndustryCategoryOptions(): DataReturn {
  const [industryCategoryOptions, setIndustryCategoryOptions] = useState<IndustryCategoryOptions>();

  const queryOption = useQuery<IndustryCategoryOptions>({
    queryKey: ["industries-option"],
    queryFn: () => getIndustryCategoryOption(),
    onSuccess: (data) => {
      if (data) {
        setIndustryCategoryOptions(data);
      }
    },
  });

  return {
    industryCategoryOptions,
    industryCategoryOptionsLoading: queryOption.isLoading,
  };
}

export default useIndustryCategoryOptions;
