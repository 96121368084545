import { Descriptions, Typography } from "antd";

const { Link } = Typography;

interface ILogItem {
  status: string;
  remarks: string;
  link: string;
  createdAt: string;
  updatedAt: string;
  createdBy: {
    email: string;
    roleId: {
      name: string;
    };
  };
}

interface IResponse {
  questionId: {
    questionText: string;
    questionType: string;
    responses: {
      responseType: string;
      responseText?: string;
      idealAnswer?: string;
      options?: string[];
      _id: string;
    }[];
    id: string;
  };
  response: string | boolean | string[];
}

const TabContent = ({
  logs,
  screeningQuestions,
  activeKey,
}: {
  logs?: ILogItem[];
  screeningQuestions?: IResponse[];
  activeKey: string;
}) => {
  switch (activeKey) {
    case "logs":
      return (
        <Descriptions bordered column={1} style={{ marginTop: 20 }}>
          {logs?.map((item, index) => (
            <Descriptions.Item
              label={index + 1 + "."}
              key={index}
              labelStyle={{ width: "50px" }}
            >
              Status : {item.status || "-"} <br />
              Remarks : {item.remarks || "-"} <br />
              Link :{" "}
              {item.link ? <Link href={item.link}>{item.link}</Link> : "-"}
              <br />
              Created at : {new Date(item.createdAt).toLocaleString() || "-"}
              <br />
              Created by : {item.createdBy?.roleId.name}
              {" - "}
              {item.createdBy?.email}
              <br />
            </Descriptions.Item>
          ))}
        </Descriptions>
      );
    case "screeningQuestions":
      return (
        <Descriptions bordered column={1} style={{ marginTop: 20 }}>
          {screeningQuestions?.map((item, index) => (
            <Descriptions.Item
              label={index + 1 + "."}
              key={item.questionId.id}
              labelStyle={{ width: "50px" }}
            >
              Question: {item.questionId.questionText} <br />
              Response:{" "}
              {Array.isArray(item.response)
                ? item.response.join(", ")
                : item.response.toString()}
            </Descriptions.Item>
          ))}
        </Descriptions>
      );
    default:
      return null;
  }
};

export default TabContent;
