import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { apiRequest } from "src/utils/API";

export type DegreeOptions = string[];

interface DataReturn {
  degreeOptions: DegreeOptions | undefined;
  degreeOptionsLoading: boolean;
}

export const getDegreeOption = async () => {
  const response = await apiRequest<DegreeOptions>(
    "GET",
    `/v1/options/degrees`
  );
  return response.data;
};

function useDegreeOptions(): DataReturn {
  const [degreeOptions, setDegreeOptions] = useState<DegreeOptions>();

  const queryOption = useQuery<DegreeOptions>({
    queryKey: ["degree-option"],
    queryFn: () => getDegreeOption(),
    onSuccess: (data) => {
      if (data) {
        setDegreeOptions(data);
      }
    },
  });

  return {
    degreeOptions,
    degreeOptionsLoading: queryOption.isLoading,
  };
}

export default useDegreeOptions;
