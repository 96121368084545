import { Menu } from "antd";
import {
  FaHome,
  FaUsers,
  FaUserTie,
  FaBuilding,
  FaBriefcase,
  FaFileAlt,
  FaBox,
  FaDollarSign,
  FaReceipt,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";

const SidebarMenu = () => {
  return (
    <Menu
      mode="inline"
      items={[
        {
          key: "home",
          label: <NavLink to="/dashboard">Analytics</NavLink>,
          title: "Home",
          icon: <FaHome />,
        },
        {
          key: "userManagement",
          label: "User Management",
          icon: <FaUsers />,
          children: [
            {
              key: "candidateManagement",
              label: <NavLink to="/users/candidate">Candidates</NavLink>,
              title: "Candidate Management",
              icon: <FaUserTie />,
            },
            {
              key: "companyManagement",
              label: <NavLink to="/users/company">Companies</NavLink>,
              title: "Company Management",
              icon: <FaBuilding />,
            },
          ],
        },
        {
          key: "jobManagement",
          label: <NavLink to="/job">Jobs</NavLink>,
          title: "Job Management",
          icon: <FaBriefcase />,
        },
        {
          key: "jobApplicationManagement",
          label: <NavLink to="/job-application">Job Applications</NavLink>,
          title: "Job Applications Management",
          icon: <FaFileAlt />,
        },
        {
          key: "packageManagement",
          label: "Package Management",
          icon: <FaBox />,
          children: [
            {
              key: "packageManagement",
              label: <NavLink to="/package">Job Post Package</NavLink>,
              title: "Job Post Package Management",
              icon: <FaDollarSign />,
            },
            {
              key: "packageManagement",
              label: (
                <NavLink to="/package/talent-search">
                  Talent Search Package
                </NavLink>
              ),
              title: "Talent Search Package Management",
              icon: <FaDollarSign />,
            },
          ],
        },
        {
          key: "transactionManagement",
          label: <NavLink to="/transaction">Transactions</NavLink>,
          title: "Transaction Management",
          icon: <FaReceipt />,
        },
      ]}
    />
  );
};

export default SidebarMenu;
