import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Descriptions,
  Image,
  message,
  Row,
  Spin,
  Tabs,
  Typography,
} from "antd";
import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { useParams } from "react-router-dom";

import { getJob, updateJob, postJob } from "../api/JobManagementAPI";
import { IJob } from "../type/JobManagement";
import JobEdit from "./JobEdit";
import PostJob from "./PostJob";
import TabContent from "./TabContent";
import { JobStatusBadge } from "src/components/shared/jobStatusBadge";

const { Title } = Typography;
const { TabPane } = Tabs;

const JobDetails = () => {
  const { id: jobId } = useParams<{ id: string }>();
  const [activeKey, setActiveKey] = useState("screeningQuestions");
  const [editVisible, setEditVisible] = useState<boolean>(false);
  const [postVisible, setPostVisible] = useState<boolean>(false);

  const {
    data: job,
    isLoading,
    isError,
    refetch: refetchGetJob,
  } = useQuery(["getJob", jobId], () => getJob(jobId), {
    enabled: !!jobId,
  });

  const updateJobMutation = useMutation(updateJob, {
    onSuccess: (data) => {
      refetchGetJob();
      message.success("Job updated successfully!");
      setEditVisible(false);
    },
    onError: (error: Error) => {
      message.error(`Failed to edit job: ${error.message}`);
    },
  });

  const postJobMutation = useMutation(postJob, {
    onSuccess: (data) => {
      refetchGetJob();
      message.success("Job posted successfully!");
      setPostVisible(false);
    },
    onError: (error: Error) => {
      message.error(`Failed to post job: ${error.message}`);
    },
  });

  const handleUpdate = (values: IJob) => {
    if (!jobId) return;
    updateJobMutation.mutateAsync({ ...values, id: jobId });
  };

  const handlePostJob = (value: string) => {
    if (!jobId) return;
    postJobMutation.mutateAsync({ jobId, jobPostPackageId: value });
  };

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  const handleEdit = () => {
    setEditVisible(true);
  };

  const handlePost = () => {
    setPostVisible(true);
  };

  const handleCancel = () => {
    setEditVisible(false);
    setPostVisible(false);
  };

  if (isLoading) {
    return <Spin tip="Loading..." />;
  }

  if (isError || !job) {
    return <div>Failed to load job details</div>;
  }

  const DESCRIPTION_ITEMS = [
    { label: "Job Title", children: job.jobTitle },
    { label: "Company Name", children: job.companyId.companyName },
    { label: "Status", children: <JobStatusBadge status={job.status} /> },
    { label: "Job Type", children: job.jobType },
    { label: "Work Mode", children: job.workMode },
    { label: "Location City", children: job.locationCity },
    { label: "Location State", children: job.locationState },
    { label: "Min. Degree", children: job.minDegree },
    { label: "Min. Experience (years)", children: job.minExperience },
    { label: "Max. Experience (years)", children: job.maxExperience },
    { label: "Min. Salary (MYR)", children: job.salaryMinAmount },
    { label: "Max. Salary (MYR)", children: job.salaryMaxAmount },
    { label: "Salary Range", children: job.salaryRange },
    { label: "Total Views", children: job.totalViews },
    {
      label: "Description",
      children: <div dangerouslySetInnerHTML={{ __html: job.description }} />,
    },
    {
      label: "Requirements",
      children: <div dangerouslySetInnerHTML={{ __html: job.requirements }} />,
    },
  ];

  return (
    <Card
      style={{
        borderRadius: 8,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "16px",
        margin: "16px",
      }}
    >
      <JobEdit
        visible={editVisible}
        onCancel={handleCancel}
        onSubmit={handleUpdate}
        initialData={job}
      />
      <PostJob
        visible={postVisible}
        onCancel={handleCancel}
        onSubmit={handlePostJob}
      />
      <Title level={2} style={{ marginBottom: "16px", textAlign: "center" }}>
        Job Details
      </Title>
      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          sm={8}
          lg={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            src={job.companyId.logo}
            alt="company logo"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "10px",
              border: "1px solid #ddd",
            }}
          />
        </Col>
      </Row>
      <Row gutter={[24, 24]} style={{ marginTop: "20px" }}>
        <Col xs={24} sm={16} lg={24}>
          <Descriptions
            title="Job Info"
            bordered
            column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2 }}
            style={{ marginBottom: "16px" }}
          >
            {DESCRIPTION_ITEMS.map((item, index) => (
              <Descriptions.Item label={item.label} key={index}>
                {item.children}
              </Descriptions.Item>
            ))}
          </Descriptions>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={handleEdit}
            >
              <MdEdit /> &nbsp; Edit Job
            </Button>
            <Button
              type="primary"
              style={{
                marginLeft: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={handlePost}
            >
              Post Job
            </Button>
          </div>
        </Col>
      </Row>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              colorBorderSecondary: "none",
            },
          },
        }}
      >
        <div style={{ borderTop: "1px solid #ddd", marginTop: "20px" }} />
        <Tabs
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onChange={handleTabChange}
          style={{ marginTop: "20px" }}
        >
          <TabPane tab="Screening Questions" key="screeningQuestions">
            <TabContent
              job={job}
              activeKey={activeKey}
              refetchGetJob={refetchGetJob}
              initialData={job}
            />
          </TabPane>
          <TabPane tab="Skills" key="skills">
            <TabContent
              job={job}
              activeKey={activeKey}
              refetchGetJob={refetchGetJob}
              initialData={job}
            />
          </TabPane>
          <TabPane tab="Specializations" key="specializations">
            <TabContent
              job={job}
              activeKey={activeKey}
              refetchGetJob={refetchGetJob}
              initialData={job}
            />
          </TabPane>
        </Tabs>
      </ConfigProvider>
    </Card>
  );
};

export default JobDetails;
