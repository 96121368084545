import React, { useEffect, useState } from "react";
import { Col, Form, Input, InputNumber, Modal, Row, Select } from "antd";
import { useParams } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import useLocationOptions from "src/hooks/useLocationOption";
import useScreeningQuestionsOptions from "src/hooks/useScreeningQuestionOption";
import useSkillOptions from "src/hooks/useSkillOption";
import useSpecializationOptions from "src/hooks/useSpecializationOption";
import useJobTypeOptions from "src/hooks/useJobTypeOption";
import useJobStatusOptions from "src/hooks/useJobStatusOption";
import useDegreeOptions from "src/hooks/useDegreeOption";
import useSalaryOptions from "src/hooks/useSalaryOption";
import useWorkModeOptions from "src/hooks/useWorkModeOption";
import { IJob } from "../type/JobManagement";

const { Option } = Select;

interface EditJobFormProps {
  visible: boolean;
  onSubmit: (values: IJob) => void;
  onCancel: () => void;
  initialData?: IJob;
}

const JobEdit: React.FC<EditJobFormProps> = ({
  visible,
  onSubmit,
  onCancel,
  initialData,
}) => {
  const { id } = useParams<{ id: string }>();
  const [description, setDescription] = useState<string>("");
  const [requirements, setRequirements] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");

  const [form] = Form.useForm();

  const { locationOptions } = useLocationOptions();
  const { screeningQuestionsOptions } = useScreeningQuestionsOptions();
  const { skillOptions } = useSkillOptions();
  const { specializationOptions } = useSpecializationOptions();
  const { jobTypeOptions } = useJobTypeOptions();
  const { jobStatusOptions } = useJobStatusOptions();
  const { degreeOptions } = useDegreeOptions();
  const { workModeOptions } = useWorkModeOptions();
  const { salaryOptions } = useSalaryOptions();

  const handleStateChange = (value: string, field?: string) => {
    if (field === "locationState") {
      form.setFieldsValue({ locationCity: undefined });
      setSelectedState(value);
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onSubmit({ ...values, description, requirements });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue(initialData);
      setDescription(initialData.description || "");
      setRequirements(initialData.requirements || "");
    }
  }, [initialData, form]);

  return (
    <Modal
      open={visible}
      width={1000}
      title="Edit Job"
      okText="Update"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="jobTitle"
              label="Job Title"
              rules={[{ required: true, message: "Please input job title" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="status"
              label="Job Status"
              rules={[
                { required: true, message: "Please select a job status" },
              ]}
            >
              <Select
                placeholder="Select a Job Status"
                onChange={(value) => handleStateChange(value, "")}
              >
                {jobStatusOptions?.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="jobType"
              label="Job Type"
              rules={[{ required: true, message: "Please select a job type" }]}
            >
              <Select
                placeholder="Select a Job Type"
                onChange={(value) => handleStateChange(value, "")}
              >
                {jobTypeOptions?.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="workMode"
              label="Work Mode"
              rules={[
                {
                  required: true,
                  message: "Please input work mode",
                },
              ]}
            >
              <Select
                placeholder="Select Work Mode"
                onChange={(value) => handleStateChange(value, "")}
              >
                {workModeOptions?.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="minDegree"
              label="Minimum Degree"
              rules={[
                {
                  required: !initialData,
                  message: "Please input the minimum degree!",
                },
              ]}
            >
              <Select
                placeholder="Select Min Degree"
                onChange={(value) => handleStateChange(value, "")}
              >
                {degreeOptions?.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="minExperience"
              label="Min. Experience (Years)"
              rules={[
                {
                  required: true,
                  message: "Please input min experience",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="maxExperience"
              label="Max. Experience (Years)"
              rules={[
                {
                  required: true,
                  message: "Please input max experience",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="locationState" label="State">
              {locationOptions && (
                <Select
                  placeholder="Select State"
                  onChange={(value) =>
                    handleStateChange(value, "locationState")
                  }
                >
                  {Object.keys(locationOptions).map((state) => (
                    <Option key={state} value={state}>
                      {state}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="locationCity" label="Location City">
              {locationOptions && (
                <Select
                  placeholder="Select City"
                  onChange={(value) => handleStateChange(value, "")}
                >
                  {locationOptions[selectedState]?.map((city: any) => (
                    <Option key={city} value={city}>
                      {city}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Please input description",
                },
              ]}
            >
              <CKEditor
                editor={ClassicEditor}
                data={description}
                onChange={(event, editor) => {
                  setDescription(editor.getData());
                }}
                config={{
                  placeholder: "Enter job description",
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="requirements"
              label="Requirements"
              rules={[
                {
                  required: true,
                  message: "Please input requirements",
                },
              ]}
            >
              <CKEditor
                editor={ClassicEditor}
                data={requirements}
                onChange={(event, editor) => {
                  setRequirements(editor.getData());
                }}
                config={{
                  placeholder: "Enter job requirements",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="salaryMinAmount"
              label="Min. Salary (MYR)"
              rules={[
                {
                  required: true,
                  message: "Please input min salary",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="salaryMaxAmount"
              label="Max. Salary (MYR)"
              rules={[
                {
                  required: true,
                  message: "Please input max salary",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="salaryRange"
              label="Salary Range"
              rules={[
                {
                  required: true,
                  message: "Please select a salary range",
                },
              ]}
            >
              <Select
                placeholder="Select Salary Range"
                onChange={(value) => handleStateChange(value, "")}
              >
                {salaryOptions?.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="highlightAds"
              label="Highlight Ads"
              rules={[
                {
                  required: true,
                  message: "Please select whether to highlight ads",
                },
              ]}
            >
              <Select onChange={(value) => handleStateChange(value, "")}>
                <Select.Option value={true}>True</Select.Option>
                <Select.Option value={false}>False</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="skills" label="Skills">
              <Select
                mode="multiple"
                placeholder="Select Skills"
                onChange={(value) => handleStateChange(value, "")}
              >
                {skillOptions?.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="specializations" label="Specializations">
              <Select
                mode="multiple"
                placeholder="Select Specializations"
                onChange={(value) => handleStateChange(value, "")}
              >
                {specializationOptions?.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item name="screeningQuestions" label="Screening Questions">
              {screeningQuestionsOptions && (
                <Select
                  mode="multiple"
                  placeholder="Select Screening Questions"
                  onChange={(value) => handleStateChange(value, "")}
                >
                  {screeningQuestionsOptions.map((question: any) => (
                    <Option key={question.id} value={question.id}>
                      {question.questionText}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default JobEdit;
