import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { apiRequest } from "src/utils/API";

export type WorkModeOptions = string[];

interface DataReturn {
  workModeOptions: WorkModeOptions | undefined;
  workModeOptionsLoading: boolean;
}

export const getWorkModeOption = async () => {
  const response = await apiRequest<WorkModeOptions>("GET", `/v1/options/work-mode-types`);
  return response.data;
};

function useWorkModeOptions(): DataReturn {
  const [workModeOptions, setWorkModeOptions] = useState<WorkModeOptions>();

  const queryOption = useQuery<WorkModeOptions>({
    queryKey: ["workMode-option"],
    queryFn: () => getWorkModeOption(),
    onSuccess: (data) => {
      if (data) {
        setWorkModeOptions(data);
      }
    },
  });

  return {
    workModeOptions,
    workModeOptionsLoading: queryOption.isLoading,
  };
}

export default useWorkModeOptions;
