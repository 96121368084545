import { Badge } from "antd";
import { BadgeProps } from "antd/lib";

const statusColors: { [key: string]: BadgeProps["status"] } = {
    open: "success",
    paused: "warning",
    expired: "error",
    drafted: "default",
};

export const JobStatusBadge = ({ status }: { status: string }) => {
    return (
        <Badge status={statusColors[status] || "default"} text={status}/>
    );
}