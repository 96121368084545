import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Descriptions,
  Image,
  message,
  Row,
  Spin,
  Tabs,
  Typography,
  Tag,
} from "antd";
import { useState } from "react";
import { MdEdit } from "react-icons/md";
import { useParams } from "react-router-dom";
import {
  getJobApplication,
  updateJobApplicationStatus,
} from "../api/JobApplicationManagementAPI";
import { IJobApplication } from "../type/JobApplicationManagement";
import JobApplicationEdit from "./JobApplicationEdit";
import TabContent from "./TabContent";
import { JobStatusBadge } from "src/components/shared/jobStatusBadge";

const { Text, Link, Title } = Typography;
const { TabPane } = Tabs;

const JobApplicationDetail = () => {
  const { id: applicationId } = useParams<{ id: string }>();
  const [activeKey, setActiveKey] = useState<string>("logs");
  const [editVisible, setEditVisible] = useState<boolean>(false);

  const {
    data: application,
    isLoading,
    isError,
    refetch: refetchJobApplication,
  } = useQuery(
    ["applicatonDetails", applicationId],
    () => {
      if (!applicationId) return;
      return getJobApplication(applicationId);
    },
    {
      enabled: !!applicationId,
    }
  );
  const updateJobApplicationStatusMutation = useMutation(
    updateJobApplicationStatus,
    {
      onSuccess: (data) => {
        message.success("Job application status updated successfully");
        setEditVisible(false);
        refetchJobApplication();
      },
      onError: (error: Error) => {
        message.error(
          `Failed to update job application status: ${error.message}`
        );
      },
    }
  );

  const handleUpdate = (value: IJobApplication) => {
    if (!applicationId) return;
    updateJobApplicationStatusMutation.mutateAsync({
      ...value,
      _id: applicationId,
    });
  };

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  const handleEdit = () => {
    setEditVisible(true);
  };

  const handleCancel = () => {
    setEditVisible(false);
  };

  if (isLoading) {
    return <Spin tip="Loading" />;
  }

  if (isError || !application) {
    return <div>Failed to load job application details</div>;
  }

  const DESCRIPTION_ITEMS = [
    {
      label: "Candidate Name",
      children: application.applicant.candidateId.candidateName,
    },
    {
      label: "Application Created at",
      children: `${new Date(application.applicant.createdAt).toLocaleString()}`,
    },
    {
      label: "Application Last Updated at",
      children: `${new Date(application.applicant.updatedAt).toLocaleString()}`,
    },
    {
      label: "Job Applicaton Status",
      children: (
        <Tag color="blue">{application.applicant.applicationStatus}</Tag>
      ),
    },
    {
      label: "Job Status",
      children: <JobStatusBadge status={application.applicant.jobId.status} />,
    },
    {
      label: "Job Title",
      children: application.applicant.candidateId.jobTitle,
    },
    {
      label: "Gender",
      children: application.applicant.candidateId.gender,
    },
    {
      label: "Location City",
      children: application.applicant.candidateId.locationCity,
    },
    {
      label: "Location State",
      children: application.applicant.candidateId.locationState,
    },
    {
      label: "Salary Amount",
      children: application.applicant.candidateId.salaryAmount,
    },
    {
      label: "Salary Expectation",
      children: application.applicant.candidateId.salaryExpectationAmount,
    },
    {
      label: "Notice Period Days",
      children: application.applicant.candidateId.noticePeriodDays,
    },
    {
      label: "Candidate Detail",
      children: (
        <Link
          href={`/users/candidate/${application.applicant.candidateId.id}`}
          target="blank"
        >
          View candidate details
        </Link>
      ),
    },
    {
      label: "Created By",
      children: `${application.applicant.createdBy?.roleId.name} - ${application.applicant.createdBy?.email}`,
    },
  ];

  return (
    <Card
      style={{
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "16px",
        margin: "16px",
      }}
    >
      <JobApplicationEdit
        visible={editVisible}
        onCancel={handleCancel}
        onSubmit={handleUpdate}
        initialData={application}
      />
      <Title level={2} style={{ marginBottom: "16px", textAlign: "center" }}>
        Job Application Details
      </Title>
      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          sm={8}
          lg={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            src={application.applicant.candidateId.photo}
            alt="user profile image"
            style={{
              borderRadius: "10px",
              border: "1px solid #ddd",
            }}
          />
        </Col>
        <Col xs={24} sm={16} lg={20}>
          <Descriptions
            title=""
            bordered
            column={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 2, xxl: 2 }}
            style={{ marginBottom: "16px" }}
          >
            {DESCRIPTION_ITEMS.map((item, index) => (
              <Descriptions.Item label={item.label} key={index}>
                {item.children}
              </Descriptions.Item>
            ))}
          </Descriptions>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              type="primary"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={handleEdit}
            >
              <MdEdit /> &nbsp; Edit Application
            </Button>
          </div>
        </Col>
      </Row>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              colorBorderSecondary: "none",
            },
          },
        }}
      >
        <div style={{ borderTop: "1px solid #ddd", marginTop: "20px" }} />
        <Tabs
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onChange={handleTabChange}
          style={{ marginTop: "20px" }}
        >
          <TabPane tab="Logs" key="logs">
            <TabContent logs={application.logs} activeKey={activeKey} />
          </TabPane>
          <TabPane tab="Screening Questions" key="screeningQuestions">
            <TabContent screeningQuestions={application.applicant.answers} activeKey={activeKey} />
          </TabPane>
        </Tabs>
      </ConfigProvider>
    </Card>
  );
};

export default JobApplicationDetail;
