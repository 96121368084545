import { Col, Form, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";

import { IJobApplication } from "../type/JobApplicationManagement";
const { Option } = Select;

interface IJobApplicationEditProps {
  visible: boolean;
  onSubmit: (values: IJobApplication) => void;
  onCancel: () => void;
  initialData?: IJobApplication;
}

const JobApplicationEdit: React.FC<IJobApplicationEditProps> = ({
  visible,
  onSubmit,
  onCancel,
  initialData,
}) => {
  const [selectedState, setSelectedState] = useState<string>("");
  const [form] = Form.useForm();

  const jobApplicationStatusOptions = [
    "Applied",
    "Shortlisted",
    "Interview",
    "Skill Test",
    "Offer",
    "Hired",
    "Rejected",
  ];

  const handleStateChange = (value: string) => {
    setSelectedState(value);
    form.setFieldsValue({ applicationStatus: value });
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onSubmit(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  useEffect(() => {
    if (initialData) {
      const data = {
        id: initialData._id,
        applicationStatus: initialData.applicationStatus,
      };
      form.setFieldsValue(data);
    }
  }, [initialData, form]);

  return (
    <Modal
      open={visible}
      width={600}
      title="Edit Job Application Status"
      okText="Update"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12} md={24}>
            <Form.Item name="applicationStatus" label="Job application status">
              {jobApplicationStatusOptions && (
                <Select
                  placeholder="Select Status"
                  allowClear
                  onChange={handleStateChange}
                >
                  {jobApplicationStatusOptions.map((state) => (
                    <Option key={state} value={state}>
                      {state}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default JobApplicationEdit;
