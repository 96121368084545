/* eslint-disable no-useless-catch */
import { apiRequest, downloadFile } from "src/utils/API";
import queryToString from "src/utils/queryToString";
import {
  IAntDProTableRequest,
  IAPIResponseGetTransaction,
  ITransaction,
} from "../type/TransactionManagement";
import formatDateTime from "src/utils/formatDateTime";

export const getTransactions = async ({
  sort,
  filter,
  params,
}: IAntDProTableRequest) => {
  params.limit = params.pageSize;
  params.page = params.current;
  delete params.pageSize;
  delete params.current;
  const newParams = queryToString({ ...params, ...filter, ...sort });
  const response = await apiRequest<IAPIResponseGetTransaction>(
    "GET",
    `/v1/admin/transaction/list?${newParams}`
  );

  return response.data;
};
export const getTransactionById = async (id: string | undefined) => {
  const response = await apiRequest<IAPIResponseGetTransaction>(
    "GET",
    `/v1/admin/transaction/${id}`
  );
  return response.data;
};

export const downloadTransactionList = async () => {
  const fileName = `Transaction_List_${formatDateTime(new Date())}.csv`;
  await downloadFile("/v1/admin/transaction/list/download", fileName);
};

export const updateTransaction = async (data: ITransaction) => {
  return await apiRequest<ITransaction>("PATCH", "/v1/admin/transaction", data);
};
