import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { apiRequest } from "src/utils/API";

export type SpecializationOptions = string[];

interface DataReturn {
  specializationOptions: SpecializationOptions | undefined;
  specializationOptionsLoading: boolean;
}

export const getSpecializationOption = async () => {
  const response = await apiRequest<SpecializationOptions>("GET", `/v1/options/specializations`);
  return response.data;
};

function useSpecializationOptions(): DataReturn {
  const [specializationOptions, setSpecializationOptions] = useState<SpecializationOptions>();

  const queryOption = useQuery<SpecializationOptions>({
    queryKey: ["specialization-option"],
    queryFn: () => getSpecializationOption(),
    onSuccess: (data) => {
      if (data) {
        setSpecializationOptions(data);
      }
    },
  });

  return {
    specializationOptions,
    specializationOptionsLoading: queryOption.isLoading,
  };
}

export default useSpecializationOptions;
