import { useQuery } from "@tanstack/react-query";
import {
  Card,
  Col,
  ConfigProvider,
  Descriptions,
  Image,
  Row,
  Spin,
  Tabs,
  Typography,
  Tag,
} from "antd";
import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";

import currencyFormatter from "src/utils/currencyFormatter";
import { getCandidate } from "../api/CandidateManagementAPI";

const { Text, Link, Title } = Typography;
const { TabPane } = Tabs;

const ExternalCandidateDetails = () => {
  const { id: candidateId } = useParams<{ id: string }>();
  const [activeKey, setActiveKey] = useState("education");

  const { data, isLoading, isError } = useQuery(
    ["candidateData", candidateId],
    () => getCandidate(candidateId),
    {
      enabled: !!candidateId,
    }
  );

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  if (isLoading) {
    return <Spin tip="Loading..." />;
  }

  const candidate = data[0];

  if (isError || !candidate) {
    return <div>Failed to load candidate details</div>;
  }

  const DESCRIPTION_ITEMS = [
    { label: "Name", children: candidate.candidateName },
    { label: "Email", children: candidate.email },
    { label: "Phone", children: candidate.phone },
    { label: "Job Title", children: candidate.jobTitle },
    {
      label: "Location",
      children: `${candidate.locationCity}, ${candidate.locationState}`,
    },
    {
      label: "Resume",
      children: (
        <Link href={candidate.resume} target="_blank">
          View Resume
        </Link>
      ),
    },
    {
      label: "Has applied a job",
      children: (
        <Tag color={candidate.jobApplications ? "green" : "red"}>
          {candidate.jobApplications ? "Yes" : "No"}
        </Tag>
      ),
    },
  ];

  const renderTabContent = () => {
    switch (activeKey) {
      case "education":
        return (
          <Descriptions
            title="Education"
            bordered
            column={1}
            style={{ marginTop: 20 }}
          >
            {candidate.educationIds?.length ? (
              candidate.educationIds.map((edu: any) => (
                <Descriptions.Item label={edu.degree} key={edu.id}>
                  <Text strong>{edu.schoolName}</Text>
                  <br />
                  <Text>Field of Study: {edu.fieldOfStudy}</Text>
                  <br />
                  <Text>
                    Duration: {moment(edu.startDate).format("YYYY/MM/DD")} -{" "}
                    {edu.endDate
                      ? moment(edu.endDate).format("YYYY/MM/DD")
                      : "Present"}
                  </Text>
                  <br />
                  <Text>
                    Currently Studying: {edu.isCurrentlyStudying ? "Yes" : "No"}
                  </Text>
                </Descriptions.Item>
              ))
            ) : (
              <Text>No education details available</Text>
            )}
          </Descriptions>
        );
      case "experience":
        return (
          <Descriptions
            title="Experience"
            bordered
            column={1}
            style={{ marginTop: 20 }}
          >
            {candidate.experienceIds?.length ? (
              candidate.experienceIds.map((exp: any) => (
                <Descriptions.Item label={exp.jobTitle} key={exp.id}>
                  <Text strong>{exp.companyName}</Text>
                  <br />
                  <Text>
                    Location: {exp.locationCity}, {exp.locationState}
                  </Text>
                  <br />
                  <Text>
                    Duration: {moment(exp.startDate).format("YYYY/MM/DD")} -{" "}
                    {exp.endDate
                      ? moment(exp.endDate).format("YYYY/MM/DD")
                      : "Present"}
                  </Text>
                  <br />
                  <Text>
                    Salary: {currencyFormatter(exp.salaryAmount)} (
                    {exp.salaryRange})
                  </Text>
                  <br />
                  <Text>
                    Currently Working: {exp.isCurrentlyWorking ? "Yes" : "No"}
                  </Text>
                </Descriptions.Item>
              ))
            ) : (
              <Text>No experience details available</Text>
            )}
          </Descriptions>
        );
      case "skills":
        return (
          <Descriptions
            title="Skills"
            bordered
            column={1}
            style={{ marginTop: 20 }}
          >
            {candidate.skills?.length ? (
              candidate.skills.map((skill: string, index: number) => (
                <Descriptions.Item label={`Skill ${index + 1}`} key={index}>
                  {skill}
                </Descriptions.Item>
              ))
            ) : (
              <Text>No skills available</Text>
            )}
          </Descriptions>
        );
      default:
        return null;
    }
  };

  return (
    <Card
      style={{
        borderRadius: 8,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        padding: "16px",
        margin: "16px",
      }}
    >
      <Title level={2} style={{ marginBottom: "16px", textAlign: "center" }}>
        Candidate Details
      </Title>
      <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
        <Col xs={24} sm={16} lg={24}>
          <Descriptions
            title="User Info"
            bordered
            column={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 3 }}
            style={{ marginBottom: "16px" }}
          >
            {DESCRIPTION_ITEMS.map((item, index) => (
              <Descriptions.Item
                label={item.label}
                key={index}
                style={{ minWidth: "100px" }}
              >
                {item.children}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Col>
      </Row>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              colorBorderSecondary: "none",
            },
          },
        }}
      >
        <div style={{ borderTop: "1px solid #ddd", marginTop: "20px" }} />
        <Tabs
          defaultActiveKey={activeKey}
          activeKey={activeKey}
          onChange={handleTabChange}
          style={{ marginTop: "20px" }}
        >
          <TabPane tab="Education" key="education">
            {renderTabContent()}
          </TabPane>
          <TabPane tab="Experience" key="experience">
            {renderTabContent()}
          </TabPane>
          <TabPane tab="Skills" key="skills">
            {renderTabContent()}
          </TabPane>
        </Tabs>
      </ConfigProvider>
    </Card>
  );
};

export default ExternalCandidateDetails;
