import React from "react";
import { Card, Form, Input, Button, Divider, message, Checkbox } from "antd";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { setUserSession } from "../../utils/AuthService";
import { ILogin, LoginSchema } from "./type/loginType";
import { login } from "./api/LoginAPI";
import logo from "../../assets/images/logo.png";
const LoginPage = () => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILogin>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      rememberme: false,
    },
  });

  const onSubmit = async (data: ILogin) => {
    const loginAcc = await login(data);
    if (loginAcc.status === "success") {
      const userDetail = loginAcc.data;
      const setUser = setUserSession({
        rememberme: data.rememberme,
        token: userDetail.tokens,
        user: userDetail.user,
      });
      setUser === true ? navigate(0) : message.error("error");
    } else {
      message.error(loginAcc.data.message);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div style={{ justifyContent: "center" }}>
        <div
          style={{ display: "flex", justifyContent: "center", margin: "20px" }}
        >
          <img alt="example" src={logo} />
        </div>
        <Card title="Login" style={{ width: 350 }}>
          <Form
            name="loginForm"
            initialValues={{ remember: true }}
            onFinish={handleSubmit(onSubmit)}
            labelAlign="left"
            colon={false}
          >
            <Form.Item
              name="phone"
              hasFeedback
              validateStatus={errors.phone ? "error" : ""}
              help={errors.phone?.message}
            >
              <Controller
                name="phone"
                control={control}
                render={({ field }) => <Input placeholder="Phone" {...field} />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              hasFeedback
              validateStatus={errors.password ? "error" : ""}
              help={errors.password?.message}
              style={{ marginBottom: 10 }}
            >
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input.Password placeholder="Password" {...field} />
                )}
              />
            </Form.Item>
            <Form.Item
              name="rememberme"
              hasFeedback
              validateStatus={errors.rememberme ? "error" : ""}
              help={errors.rememberme?.message}
            >
              <Controller
                name="rememberme"
                control={control}
                render={({ field }) => (
                  <Checkbox checked={field.value} {...field}>
                    Remember Me?
                  </Checkbox>
                )}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%", marginBottom: 5 }}
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default LoginPage;
