import { useMutation } from "@tanstack/react-query";
import { Form, Input, Modal, Select, message } from "antd";
import React from "react";
import { useParams } from "react-router-dom";

import {
  createScreeningQuestion,
  updateJobScreeningQuestion,
} from "../api/JobManagementAPI";
import { IJob } from "../type/JobManagement";

const { Option } = Select;

interface ScreeningQuestionAddProps {
  visible: boolean;
  onCancel: () => void;
  refetchGetJob: () => void;
  initialData: IJob;
}

const responseTypeOptions = ["text", "boolean", "multiple-choice"];
const questionTypeOptions = ["Experience", "Custom", "License/Certification"];
const multipleChoiceAnswerOptions = ["true", "false"];

const ScreeningQuestionAdd: React.FC<ScreeningQuestionAddProps> = ({
  visible,
  onCancel,
  refetchGetJob,
  initialData,
}) => {
  const { id: jobId } = useParams<{ id: string }>();
  const [form] = Form.useForm();

  const createScreeningQuestionMutation = useMutation(createScreeningQuestion, {
    onSuccess: (result) => {
      handleUpdate(result.data.id);
    },
    onError: (error: Error) => {
      message.error(`Failed to create screening questions: ${error.message}`);
    },
  });

  const updateJobScreeningQuestionsMutation = useMutation(
    updateJobScreeningQuestion,
    {
      onSuccess: () => {
        refetchGetJob();
        message.success("Screening question created successfully!");
        handleCancel();
      },
      onError: (error: Error) => {
        message.error(`Failed to edit job: ${error.message}`);
      },
    }
  );

  const handleUpdate = (newScreeningQuestionId: string) => {
    if (!jobId) return;
    updateJobScreeningQuestionsMutation.mutateAsync({
      id: jobId,
      screeningQuestions: [
        ...initialData.screeningQuestions.map((question) => question.id),
        newScreeningQuestionId,
      ],
    });
  };

  const addNewQuestion = (values: any) => {
    const {
      questionText,
      questionType,
      responseType,
      options,
      responseText,
      idealAnswer,
      allowMultipleAnswer,
    } = values;
    createScreeningQuestionMutation.mutateAsync({
      questionText,
      questionType,
      responses: [
        {
          responseType,
          responseText,
          idealAnswer,
          options: options || [],
          allowMultipleAnswer: allowMultipleAnswer === "true" ? true : false,
        },
      ],
    });
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        addNewQuestion(values);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      open={visible}
      width={600}
      title="Add New Screening Question"
      okText="Create"
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ responseType: "text", questionType: "Experience" }}
      >
        <Form.Item
          name="questionText"
          label="Enter a new question text"
          rules={[
            {
              required: true,
              message: "Please input question text",
            },
          ]}
        >
          <Input placeholder="Enter new question text" />
        </Form.Item>
        <Form.Item
          name="questionType"
          label="Select a question type"
          rules={[
            {
              required: true,
              message: "Please select a question type",
            },
          ]}
        >
          <Select placeholder="Select a question type">
            {questionTypeOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="responseType"
          label="Select a response type"
          rules={[
            {
              required: true,
              message: "Please select a response type",
            },
          ]}
        >
          <Select placeholder="Select a response type">
            {responseTypeOptions.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="responseText"
          label="Response Text"
          rules={[
            {
              required: true,
              message: "Please input response text",
            },
          ]}
        >
          <Input placeholder="Enter response text" />
        </Form.Item>
        <Form.Item name="idealAnswer" label="Ideal Answer">
          <Input placeholder="Enter ideal answer" />
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.responseType !== currentValues.responseType
          }
          noStyle
        >
          {({ getFieldValue }) =>
            getFieldValue("responseType") === "multiple-choice" ? (
              <>
                <Form.Item
                  name="options"
                  label="Options for Multiple Choice"
                  rules={[
                    {
                      required: true,
                      message: "Please input multiple-choice options",
                    },
                  ]}
                >
                  <Select
                    mode="tags"
                    placeholder="Select or add multiple choice options"
                    tokenSeparators={[","]}
                    style={{ width: "100%" }}
                  ></Select>
                </Form.Item>

                <Form.Item
                  name="allowMultipleAnswer"
                  label="Allow multiple answers"
                >
                  <Select placeholder="Select one">
                    {multipleChoiceAnswerOptions.map((option) => (
                      <Option key={option} value={option} defaultValue="true">
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            ) : null
          }
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ScreeningQuestionAdd;
