import { Line, LineConfig } from "@ant-design/plots";
import { useEffect, useState } from "react";
import { getAnalyticCandidate } from "./api/CandidateChartAPI";
import dayjs from 'dayjs';
import { DatePicker } from 'antd';

const { MonthPicker } = DatePicker;

export const CandidatesChart = () => {
  const [results, setResults] = useState<any>(null);
  const [data, setData] = useState<any[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<dayjs.Dayjs | null>(dayjs());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const candidates = await getAnalyticCandidate();
        setResults(candidates);

        if (selectedMonth) {
          const month = selectedMonth.month();
          const year = selectedMonth.year();

          const weeklyData: { [key: string]: number } = {
            "Week 1": 0,
            "Week 2": 0,
            "Week 3": 0,
            "Week 4": 0,
            "Week 5": 0,
          };

          
          candidates.forEach((candidate: any) => {
            const createdAt = dayjs(candidate.createdAt);
            if (createdAt.month() === month && createdAt.year() === year) {
              
              const startOfMonth = dayjs(createdAt).startOf('month');
              const weekOfMonth = Math.ceil((createdAt.date() - startOfMonth.date() + 1) / 7);
              const weekKey = `Week ${weekOfMonth}`;

              if (weeklyData[weekKey] !== undefined) {
                weeklyData[weekKey]++;
              }
            }
          });

          
          const chartData = Object.keys(weeklyData).map(week => ({
            week,
            value: weeklyData[week]
          }));

          setData(chartData);
        }
      } catch (error) {
        console.error("Error fetching candidates data:", error);
      }
    };

    fetchData();
  }, [selectedMonth]);

  const handleMonthChange = (date: dayjs.Dayjs | null, dateString: string | string[]) => {
    setSelectedMonth(date);
  };

  const config: LineConfig = {
    data,
    height: 300,
    xField: "week",
    yField: "value",
    point: {
      size: 5,
      shape: "diamond",
    },
  };

  return (
    <div>
      <MonthPicker
        defaultValue={dayjs()}
        format="YYYY-MM"
        onChange={handleMonthChange}
        style={{ marginBottom: 16 }}
      />
      <Line {...config} />
    </div>
  );
};
