import { Breadcrumb, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const DynamicBreadcrumb: React.FC = () => {
  const location = useLocation();
  const { Text } = Typography;
  type TBreadcrumb = { title: JSX.Element | string };
  const [breadcrumbs, setBreadcrumbs] = useState<TBreadcrumb[]>([]);

  const generateBreadcrumbs = () => {
    const crumbs: TBreadcrumb[] = [];

    const routes = location.pathname.split("/");
    routes.forEach((route, index) => {
      const path = routes.slice(0, index + 1).join("/");

      if (index > 0 && index !== routes.length - 1) {
        crumbs.push({
          title: <a href={path}>{route.toUpperCase()}</a>,
        });
      } else if (index === routes.length - 1) {
        if (location.state?.name) {
          crumbs.push({
            title: (
              <Text style={{ color: "#17183B", fontWeight: "bold" }}>
                {location.state.name.toUpperCase()}
              </Text>
            ),
          });
        } else {
          crumbs.push({
            title: (
              <Text style={{ color: "#17183B", fontWeight: "bold" }}>
                {route.toUpperCase()}
              </Text>
            ),
          });
        }
      }
    });

    setBreadcrumbs(crumbs);
  };

  useEffect(() => {
    generateBreadcrumbs();
  }, [location]);

  return <Breadcrumb items={breadcrumbs} separator={">"} />;
};

export default DynamicBreadcrumb;
